/* About Us */

#about {
    background: black url('/assets/img/stock/kicc.webp') no-repeat center;
    background-size: cover;
    width: 100%;
    height: 800px;
    color: white;
    .content {
        display: flex;
        width: 80%;
        flex-direction: column;
        justify-content: center;
    }
    p {
        font-size: 1.125rem;
    }
}

@media only screen and (max-width: 1023px) {
    #about {
        background: black url('/assets/img/stock/kicc-lr.webp') no-repeat center;
        background-size: cover;
        height: auto;
    }
}