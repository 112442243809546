/* Intro */

#top {
    background: #222 url('/assets/img/stock/intro.webp') no-repeat fixed center bottom;
    background-size: cover;
    width: 100%;
    height: 800px;
    color: white;
    .content {
        display: flex;
        width: 90%;
        flex-direction: column;
        justify-content: center;
    }
    p {
        font-size: 1.5rem;
    }
}

#welcome {
    padding-top: 75px;
    font-family: "Great Vibes", "Edwardian Script", "Brush Script MT", "Monotype Corsiva", serif;
    font-size: 8rem;
    transition: opacity 0.2s ease-in-out;
}

.contact-button {
    background-color: black;
    border: 1px solid white;
    border-radius: 5px;
    margin: 20px auto;
    padding: 20px;
    width: 500px;
    color: white;
    font-family: inherit;
    font-size: 1.5rem;
    text-transform: uppercase;
    transition: color 0.2s ease-in-out;
    &:hover {
        cursor: pointer;
        color: $darkgold;
        transition: color 0.2s ease-in-out;
    }
}

@media only screen and (max-width: 1023px) {
    #top {
        background: #222 url('/assets/img/stock/intro-lr.webp') no-repeat fixed center bottom;
        background-size: cover;
        height: 1000px;
    }
    #welcome {
        font-size: 4rem;
    }
    .contact-button {
        width: 100%;
    }
}