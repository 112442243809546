@mixin section($color, $img-url, $options, $height) {
    display: flex;
    background: $color url($img-url) $options;
    background-size: cover;
    width: 100%;
    height: $height;
    color: invert($color);
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

@mixin section-content($width, $height) {
    display: flex;
    width: $width;
    height: $height;
    flex-direction: column;
    justify-content: center;
}