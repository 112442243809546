/* Services */

#services {
    @include section(white, '/assets/img/stock/kencom.webp', no-repeat fixed center bottom, auto);
    .background {
        padding: 100px 0;
    }
    .box-list {
        display: inline-flex;
        width: 90%;
        justify-content: space-around;
        align-items: baseline;
        flex-wrap: wrap;
        margin: 0 auto;
        p {
            font-size: 1.5rem;
        }
    }
    .icon {
        height: 100px;
        transition: opacity 0.2s ease-in-out;
    }
    .content {
        display: flex;
        width: 300px;
        height: 300px;
        flex-direction: column;
        justify-content: center;
        h2 {
            margin-top: 30px;
        }
    }
    a {
        color: black;
        transition: opacity 0.2s ease-in-out;
        &:hover,
        &:focus {
            opacity: 0.7;
            transition: opacity 0.2s ease-in-out;
        }
    }
}

#interpreting,
#translation,
#conference {
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $darkblue;
    color: white;
    width: 100%;
    height: 800px;
    justify-content: center;
    .background {
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    .content {
        width: 50%;
        text-align: left;
    }
    h3 {
        font-size: 2rem;
    }
    p {
        font-size: 1.125rem;
    }
    ul {
        font-size: 1.125rem;
    }
}

#interpreting {
    background-image: url('/assets/img/stock/interpreting.webp');
    background-position: center top;
    img {
        width: auto;
        height: 400px;
    }
    ul {
        column-count: 2;
    }
}

#translation {
    background-image: url('/assets/img/stock/translation.webp');
    background-position: center top;
    ul:last-child {
        column-count: 2;
    }
    img {
        width: 267px;
        height: auto;
    }
}

#conference {
    background-image: url('/assets/img/stock/conference.webp');
    background-position: center bottom;
    img {
        width: 267px;
        height: auto;
    }
}

@media only screen and (max-width: 1023px) {
    #services {
        background: white url('/assets/img/stock/kencom-lr.webp') no-repeat fixed center bottom;
        background-size: cover;
    }
    #interpreting {
        background-image: url('/assets/img/stock/interpreting-lr.webp');
    }
    #translation {
        background-image: url('/assets/img/stock/translation-lr.webp');
    }
    #conference {
        background-image: url('/assets/img/stock/conference-lr.webp');
    }
    #interpreting,
    #translation,
    #conference {
        height: auto;
        img {
            display: none;
        }
    }
}