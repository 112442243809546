/* Team */

#team {
    background-color: white;
    width: 100%;
    height: auto;
    padding: 100px 0;
    color: black;
    .team-list {
        display: flex;
        width: 90%;
        margin: 0 auto;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
    }
    .team-bio {
        display: flex;
        width: 750px;
        height: 550px;
        padding: 20px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .team-desc {
        display: flex;
        margin-left: 50px;
        text-align: left;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        h3 {
            font-size: 1.5rem;
        }
        .job-title {
            font-size: 1.125rem;
        }
    }
    .team-img {
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        min-width: 225px;
        min-height: 300px;
        border: 1px solid black;
    }
    .team-pool {
        display: flex;
        width: 750px;
        height: 550px;
        padding: 20px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        img {
            width: 225px;
            height: auto;
        }
    }
    h3 {
        margin: 0 10px;
        padding: 0;
    }
}

@media only screen and (max-width: 1023px) {
    #team {
        padding: unset;
        .team-bio,
        .team-pool {
            width: 100%;
            height: unset;
            flex-direction: column;
        }
        .team-desc {
            text-align: center;
            margin-left: unset;
        }
        .team-img {
            margin-bottom: 1rem;
        }
    }
}