/* Policies page */

#p-intro {
    @include section(white, '/assets/img/stock/contact.webp', no-repeat fixed center, auto);
    .background.error {
        min-height: 95vh;
    }
    .content {
        @include section-content(80%, inherit);
        margin-top: 100px;
        padding: 200px 0;
    }
    p {
        font-size: 1.5rem;
    }
}

#p-cookie {
    background-color: black;
    width: 100%;
    height: auto;
    padding: 100px;
    text-align: left;
    color: white;
}

#p-privacy {
    background-color: white;
    width: 100%;
    height: auto;
    padding: 100px;
    text-align: left;
    color: black;
    .policy {
        width: 100%;
        height: 475px;
        overflow-x: hidden;
        overflow-y: scroll;
        word-wrap: normal;
        a {
            color: $darkblue;
            transition: color 0.2s ease-in-out;
            &:hover {
                color: $grey;
                transition: color 0.2s ease-in-out;
            }
        }
    }
}

@media only screen and (max-width: 1023px) {
    #p-intro {
        background: white url('/assets/img/stock/contact-lr.webp') no-repeat fixed center;
        background-size: cover;
    }
    #p-cookie,
    #p-privacy {
        padding: 7.5% 7.5%;
    }
    #p-privacy .policy {
        height: 700px;
    }
}