/* HEADER ELEMENTS */

header {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100px;
    background: rgb(174, 209, 223);
    background: linear-gradient(180deg, rgba(174, 209, 223, 1) 0%, rgba(60, 145, 180, 1) 100%);
    border-bottom: 2px solid black;
    box-shadow: 0px 2px 20px #808080;
    padding: 0 50px;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
}

.header-logo {
    height: 75px;
    margin-right: 50px;
}