/* Footer */

footer {
    position: relative;
    bottom: 0;
    background-color: #070707;
    border-top: 1px solid #707070;
    color: white;
    width: 100%;
    margin: 0;
    padding: 0;
    .content {
        display: flex;
        padding: 0 2rem !important;
        justify-content: space-between;
        align-items: center;
    }
    p {
        font-size: 0.875rem;
        line-height: 1.2;
    }
}

@media only screen and (max-width: 1023px) {
    footer {
        height: auto;
        flex-direction: column;
        .content {
            flex-direction: column;
            padding: 1rem !important;
        }
        p {
            width: 100%;
            margin: 0.5rem 0 !important;
        }
    }
}