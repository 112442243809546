/* Contact */

#contact {
    @include section(black, '/assets/img/stock/contact.webp', no-repeat fixed center bottom, auto);
    .background {
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    .content {
        @include section-content(inherit, inherit);
        padding: 100px 0;
        &>h2 {
            margin: 30px auto;
        }
        &>p {
            margin: 10px auto;
        }
    }
    .contact-container {
        display: flex;
        width: 100%;
        height: auto;
        text-align: left;
        justify-content: space-around;
        align-items: flex-start;
        flex-wrap: wrap;
    }
    .contact-details {
        display: block;
        width: 720px;
        margin-bottom: 50px;
        h3 {
            font-size: 1.5rem;
        }
        p {
            font-size: 1.125rem;
        }
    }
    .contact-form {
        display: flex;
        width: 700px;
        flex-direction: column;
        align-items: flex-start;
        .label-input {
            display: flex;
            flex-direction: column;
        }
        input,
        select,
        textarea {
            width: 100%;
            margin: 5px 0 10px 0;
            padding: 5px;
            font-family: inherit;
            font-size: inherit;
        }
        textarea {
            height: 100px;
        }
        .languages {
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: nowrap;
            select {
                width: 300px;
            }
        }
        .email-phone {
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: nowrap;
            select,
            input {
                width: 300px;
            }
        }
        .form-end {
            display: flex;
            width: 100%;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;
            margin: 1rem 0;
        }
        #g-recaptcha-response {
            display: block !important;
            position: absolute;
            margin: -50px 0 0 0 !important;
            z-index: -999999;
            opacity: 0;
        }
        button[type=submit] {
            background-color: black;
            border: 1px solid white;
            border-radius: 5px;
            padding: 16px;
            width: 300px;
            color: white;
            font-family: inherit;
            font-size: 1.5rem;
            text-transform: uppercase;
            transition: color 0.2s ease-in-out;
            &:hover {
                cursor: pointer;
                color: $darkgold;
                transition: color 0.2s ease-in-out;
            }
        }
        #form-status {
            display: none;
            width: 700px;
            height: auto;
            font-size: 1.125rem;
            text-align: center;
        }
    }
}

@media only screen and (max-width: 1023px) {
    #contact {
        background: black url('/assets/img/stock/contact-lr.webp') no-repeat fixed center bottom;
        background-size: cover;
        .contact-form {
            input,
            select,
            textarea {
                margin: 0.5rem 0 1.5rem 0;
            }
            .languages {
                flex-direction: column;
                select {
                    width: 100%;
                }
            }
            .email-phone {
                flex-direction: column;
                select,
                input {
                    width: 100%;
                }
            }
            .form-end {
                display: flex;
                width: 100%;
                flex-direction: column;
                align-content: center;
            }
            .g-recaptcha {
                margin-bottom: 10px;
            }
        }
    }
}