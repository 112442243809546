/* Imports */

@import "helper/variables";
@import "helper/mixins";
@import "components/header";
@import "components/nav";
@import "components/cookie-banner";
@import "components/footer";
@import "pages/intro";
@import "pages/about";
@import "pages/services";
@import "pages/team";
@import "pages/contact";
@import "pages/policy";

/* GENERAL ELEMENTS */

* {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

html {
    font-size: 18px;
}

body {
    background-color: #fff;
    width: 100%;
    font-family: "Jost", "Century Gothic", "Calibri", "Bahnschrift", sans-serif;
    margin: 0;
    padding: 0;
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.5rem;
}

h3 {
    font-size: 1.125rem;
}

h4 {
    font-size: 1rem;
}

h5 {
    font-size: 0.875rem;
}

h6 {
    font-size: 0.6875rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 1.25rem 0;
}

p {
    margin: 0.875rem 0;
}

a {
    text-decoration: none;
    color: $gold;
    transition: color 0.2s ease-in-out;
    &:hover {
        color: $grey;
        transition: color 0.2s ease-in-out;
    }
}

li {
    margin: 0.1875rem 0;
    line-height: 1.2;
}

ul,
ol {
    margin: 1rem 0;
    padding-left: 2.5rem;
}

ul {
    list-style-type: disc;
}

ol {
    list-style-type: decimal;
}

em {
    font-style: italic;
}

.uppercase {
    text-transform: uppercase;
}

.regular {
    font-weight: 400;
}

.medium {
    font-weight: 500;
}

.semi-bold {
    font-weight: 600;
}

strong,
.bold {
    font-weight: 700;
}


/* MAIN ELEMENTS */

main {
    display: flex;
    position: relative;
    width: 100%;
    text-align: center;
    flex-direction: column;
    align-items: center;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        padding: 0;
        margin: 10px;
        line-height: 1.2;
    }
    h2 {
        font-size: 2rem;
    }
}

section {
    overflow: hidden;
    &:first-child {
        margin-top: 100px;
    }
    &:not(:first-child) {
        border-top: 1px solid black;
        overflow: hidden;
    }
}

.title {
    margin-bottom: 50px;
}

.tcolor {
    color: $gold;
}

.background {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bg-black {
    background-color: rgba(0, 0, 0, 0.85);
}

.bg-white {
    background-color: rgba(255, 255, 255, 0.6);
}

.zoom {
    opacity: 0;
    transform: scale(0.8);
    transition: transform 1s;
    transition-property: opacity, transform;
    transition-timing-function: ease;
}

@media only screen and (max-width: 1023px) {
    .background {
        padding: 100px 1rem !important;
    }
    .content {
        padding: unset !important;
        width: 100% !important;
    }
}