/* MENU */

#mobile-menu {
    display: flex;
    width: 90%;
    text-align: center;
    justify-content: flex-end;
}

.menu {
    display: flex;
    width: inherit;
    margin: 0px;
    padding: 0;
    justify-content: space-evenly;
    li {
        display: inline-flex;
        flex-basis: 175px;
        padding: 10px 0;
        font-size: 1.1rem;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        justify-content: center;
    }
    a {
        margin: 0;
        padding: 0;
        color: black;
        text-decoration: none;
        transition: color 0.2s ease-in-out;
        &:hover,
        &:focus {
            color: $darkgold;
            transition: color 0.2s ease-in-out;
        }
    }
}

.mobile-nav-open,
.mobile-nav-close,
.mobile-nav-logo,
.mobile-nav-footer {
    display: none;
}

@media only screen and (max-width: 1023px) {
    .mobile-nav-open {
        display: block;
        height: 30px;
        width: auto;
    }
    .mobile-nav-close {
        display: block;
        position: absolute;
        top: 50px;
        right: 50px;
    }
    .mobile-nav-open:active,
    .mobile-nav-close:active {
        opacity: 0.6;
    }
    #mobile-menu {
        position: fixed;
        background-color: rgba(49, 39, 131, 0.99);
        box-shadow: -2px -4px 10px black;
        top: 0;
        bottom: 0;
        right: calc(-10px - 100%);
        width: 100%;
        padding-top: 35%;
        overflow-x: hidden;
        overflow-y: scroll;
        z-index: 100;
        transition: 0.25s linear;
    }
    .menu {
        display: flex;
        width: 100%;
        margin: 0 auto;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        li {
            display: inline-flex;
            flex-basis: 100px;
            text-align: center;
            justify-content: center;
            a {
                color: white;
            }
        }
    }
    .mobile-nav-logo {
        display: block;
        transition: opacity 0.2s ease-in-out;
        img {
            width: 150px;
        }
        &:hover {
            opacity: 0.6;
            transition: opacity 0.2s ease-in-out;
        }
    }
}