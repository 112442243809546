/* Cookie pop-up */

#cookie-banner {
    display: none;
    position: fixed;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    border-top: 1px solid black;
    color: black;
    width: 100%;
    padding: 20px 0;
    z-index: 100;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    p {
        width: 80%;
        margin: 0 auto 20px auto;
    }
    a {
        color: $darkblue;
        transition: color 0.2s ease-in-out;
        &:hover,
        &:focus {
            color: $grey;
            transition: color 0.2s ease-in-out;
        }
    }
    button {
        display: inline-flex;
        background-color: $darkblue;
        width: 200px;
        border: 1px solid black;
        border-radius: 5px;
        margin: 0 auto;
        padding: 5px;
        color: $gold;
        font-family: inherit;
        font-size: 1.125rem;
        text-transform: uppercase;
        transition: opacity 0.2s ease-in-out;
        justify-content: center;
        &:hover,
        &:focus {
            cursor: pointer;
            opacity: 0.9;
            transition: opacity 0.2s ease-in-out;
        }
    }
}

#cookie-close {
    position: absolute;
    width: 20px;
    height: auto;
    top: 20px;
    left: 20px;
    transition: opacity 0.2s ease-in-out;
    &:hover,
    &:focus {
        cursor: pointer;
        opacity: 0.8;
        transition: opacity 0.2s ease-in-out;
    }
}

@media only screen and (max-width: 1023px) {
    #cookie-banner {
        top: calc(50% - 150px);
        bottom: initial;
        border: 1px solid black;
        border-radius: 5px;
        color: black;
        width: 80%;
        height: 300px;
        padding: 20px 0;
        z-index: 100;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }
    #cookie-close {
        width: 24px;
        height: auto;
    }
}